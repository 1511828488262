export const appURL =
  process.env.REACT_APP_LIVE_URL || "https://api.taggoai.com";
export const appChatURL = process.env.REACT_APP_URL || "http://localhost:5000";
export const accessToken = process.env.REACT_APP_API_KEY;
export const socketURL =
  process.env.REACT_APP_SOCKET_IO_URL || "https://api.taggoai.com";
export const poweredBy = "Powered by TaggoAI";
export const taggoaiUrl = "https://app.taggo.chat";
export const photoUrl = "https://photos.taggoai.com";
export const PathImage = "https://taggoai.s3.ap-southeast-2.amazonaws.com/";

export function formatTime(inputTime: string): string {
  const date = new Date(inputTime);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${hours}:${minutes} ${day}/${month}`;
}

export function generateString(maxLength: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < maxLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertArrayToString(arr: [], shift?: boolean) {
  if (shift) arr.shift();
  return arr.join(", ");
}
export const replaceName = (text: string, name: string) => {
  const nameToReplace = name.trim() === "" ? "" : `**${name}**`;
  return text.replace("@name", nameToReplace);
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const domainName = window?.location?.hostname;
export const isAppDomain = domainName === "app.taggo.chat";

export const firstWords = (text: string) => {
  return text ? text.split(" ").slice(0, 3).join(" ") : "";
};

export const isImageLink = (link: string) => {
  return link.toLowerCase().match(/\.(jpeg|jpg|png|gif|webp)$/) != null;
};

function isValidateEmail(value: string) {
  if (!value || typeof value !== "string" || !/\S+@\S+\.\S+/.test(value)) {
    return false;
  }
  return true;
}

// function isValidateName(value: string) {
//   if (
//     !value ||
//     typeof value !== "string" ||
//     !/[a-zA-Z]/.test(value) ||
//     /[^a-zA-Z0-9\s]/.test(value)
//   ) {
//     return false;
//   }
//   return true;
// }
function isValidateName(value: string) {
  if (
    !value ||
    typeof value !== "string" ||
    value.length < 2 ||
    /[^a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẵặẹẻẽềểỄỆỉịọỏốồổỗộớờởỡợụủứừửữựỳỵỷỹ\s]/.test(
      value
    )
  ) {
    return false;
  }
  return true;
}

export const validates = {
  isValidateEmail,
  isValidateName,
};

export const isValidJson = (string: string) => {
  try {
    JSON.parse(string);
    return true; // The string is valid JSON
  } catch (error) {
    return false; // The string is not valid JSON
  }
};
