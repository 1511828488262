import { useEffect, useRef } from "react";
import ChatItem from "./chat-item";
import ChatAvatar from "./chat-avatar";
import Markdown from "react-markdown";
import { Button } from "../element/button";
import { Flow } from "./chat-flow";
import { useApp } from "../../../AppContext";
import { isMobile, isAppDomain, replaceName } from "../../../libs";

const renderers = {
  a: (props) => {
    let { href, children } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  },
};

export default function ChatMessages({
  messages,
  reload,
  dataBot,
  setInput,
  handleSubmit,
  handleCreateMess,
  setIsAgent,
  sendAgent,
  lead,
  setMessages,
  mess,
  append,
  closeMess,
  setCloseMess,
  uuid,
  flows,
  setFlow,
  flow,
  messResult,
  setMessResult,
  isAgent,
  newchat,
  newFlow,
}) {
  const { page, translations } = useApp();
  const scrollableChatContainerRef = useRef(null);

  useEffect(() => {
    if (scrollableChatContainerRef) {
      scrollableChatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length, messages]);

  const handeSelectProms = async (prompt) => {
    setInput(prompt);
  };

  const hanldeClick = (prompt, isAgent) => {
    setIsAgent(isAgent);
    setInput(prompt);
    handleCreateMess(true, prompt, "user");
    if (isAgent) {
      sendAgent();
    }
  };

  return (
    <div
      className={`w-full h-[85%] max-md:max-w-[100vw]  max-w-5xl pb-3 ${
        isMobile && "h-[85%]"
      }`}
    >
      {(flows && flows.length > 0 && !newchat) || (newFlow && newchat) ? (
        <>
          <Flow
            flows={flows}
            isFull={isAppDomain}
            messages={messages}
            setMessages={setMessages}
            mess={mess}
            append={append}
            reload={reload}
            dataBot={dataBot}
            closeMess={closeMess}
            setCloseMess={setCloseMess}
            setFlow={setFlow}
            messResult={messResult}
            setMessResult={setMessResult}
            flow={flow}
            uuid={uuid}
            lead={lead}
            handleCreateMess={handleCreateMess}
            setIsAgent={setIsAgent}
            sendAgent={sendAgent}
            isAgent={isAgent}
            newFlow={newFlow}
          />
          <div ref={scrollableChatContainerRef} />
        </>
      ) : (
        <div id="stylescroll" className="h-full overflow-auto">
          <div className="flex items-center gap-3 py-3 pe-3">
            <ChatAvatar />
            <Markdown className="break-words" components={renderers}>
              {replaceName(
                dataBot?.welcomeMessage ??
                  (`${translations?.hello_how_can_i_help}` ||
                    "Hello @name, 👋 I'm your AI Assistant. How can I help you?"),
                lead?.name || ""
              )}
            </Markdown>
          </div>
          {dataBot?.prompts && page == "init" && (
            <div className="mb-3 ">
              {dataBot.prompts.map((item, idx) => (
                <form
                  key={idx}
                  onSubmit={handleSubmit}
                  style={{
                    justifyContent: "end",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                  className="flex items-start gap-4 py-1"
                >
                  <Button
                    onClick={() =>
                      hanldeClick(
                        item?.text && item?.text !== ""
                          ? item.text
                          : item?.prompt,
                        false
                      )
                    }
                    label={item?.prompt}
                  />
                </form>
              ))}
            </div>
          )}

          <div className={`flex flex-col gap-3`}>
            {messages.map((m) => (
              <ChatItem
                key={m.id}
                message={m}
                mess={mess}
                append={append}
                setMessages={setMessages}
                reload={reload}
                dataBot={dataBot}
              />
            ))}
          </div>

          <div ref={scrollableChatContainerRef} />
        </div>
      )}
    </div>
  );
}
