import "./tailwind.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { isAppDomain, appURL, isValidJson, PathImage } from "../libs";
import { socketURL, generateString } from "../libs";
import io from "socket.io-client";
import InitComponent from "./ui/init/InitComponent";
import ChatNew from "./ui/newchat/ChatNewComponent";
import { useApp } from "../AppContext";
import { ReactComponent as ChatIcon } from "./icons/chat.svg";
import { ReactComponent as SendIcon } from "./icons/send.svg";
import { ReactComponent as ChevronBackIcon } from "./icons/chevronback.svg";
import { ReactComponent as ChatbubblesIcon } from "./icons/chatbubbles.svg";
import { isMobile } from "react-device-detect";

export default function ChatBotInit({
  dataBot,
  botId,
  flows,
  uuid,
  lead,
  setLead,
  setShow,
  start,
}) {
  const audioRef = useRef(null);
  const ws = useRef(null);
  const [closeMess, setCloseMess] = useState(false);
  const [messages, setMessages] = useState();
  const [newChat, setNewChat] = useState(true);
  const [prompt, setPrompt] = useState();
  const [checkContinue, setContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const {
    translations,
    chatbot,
    setInbox,
    inboxId,
    setCurrentPage,
    page,
    flow,
    setFlow,
    setNotifi,
    ischat,
  } = useApp();
  useEffect(() => {
    HanldeGetDataMess();
    setCurrentPage(start);
  }, []);
  const HanldeGetDataMess = async () => {
    setInbox(undefined);
    setContinue(true);
    setLoading(true);
    if (uuid && botId) {
      await axios
        .get(`${appURL}/api/inbox?botId=${botId}&uuid=${uuid}`)
        .then((res) => {
          const sortdata = res.data.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          setMessages(sortdata);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const hanldeClickBack = () => {
    if (page == "message") {
      setCurrentPage("messages");
    } else {
      setCurrentPage(start || "home");
    }
    setInbox(undefined);
  };
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
      });
    }
  };
  console.log("ischat", ischat);
  useEffect(() => {
    const socket = io(socketURL);
    socket.on("connect", () => {
      // console.log("Is connected");
    });

    socket.on(`message ${botId} ${uuid}`, (message) => {
      if (message.type === "agent") {
        // let Array = messages;
        // const messAssistant = {
        //   content: message.message,
        //   id: generateString(8),
        //   role: "agent",
        //   data: message.file
        //     ? {
        //         type: message.file.type,
        //         image_url: {
        //           url: `${appURL}/${message.file.uri}`,
        //         },
        //       }
        //     : null,
        // };
        // Array.push(messAssistant);
        // setMessages(Array);
        // reload();
        setNotifi(true);
        playAudio();
      }
    });
    socket.on("connect_error", (err) => {
      //console.log(`connect_error due to ${err.message}`);
    });
    return () => {
      socket.disconnect();
    };
  }, [botId, uuid, messages]);

  // useEffect(() => {
  //   if (botId && uuid && !ischat) {
  //     ws.current = new WebSocket(`${socketURL}/api/inbox`);
  //     ws.current.onopen = () => {
  //       console.log("Connected to WebSocket22");
  //       const clientInfo = { botId, uuid };
  //       if (ws.current) {
  //         ws.current.send(JSON.stringify(clientInfo));
  //       }
  //     };
  //     ws.current.onmessage = (event) => {
  //       let receivedMessage;
  //       if (event.data instanceof ArrayBuffer) {
  //         const buffer = new Uint8Array(event.data);
  //         const decoder = new TextDecoder("utf-8");
  //         receivedMessage = JSON.parse(decoder.decode(buffer));
  //       } else {
  //         // Assume it's a regular text message
  //         receivedMessage = isValidJson(event.data)
  //           ? JSON.parse(event.data)
  //           : null;
  //       }
  //       const formattedMessage = {
  //         type: receivedMessage?._doc?.type,
  //       };

  //       if (
  //         receivedMessage?.botId === botId &&
  //         receivedMessage?.uuid === uuid
  //       ) {
  //         if (formattedMessage.type === "agent") {
  //           playAudio();
  //           setNotifi(true);
  //         }
  //       }
  //     };
  //   }
  //   return () => {
  //     if (ws.current) {
  //       ws.current.close();
  //     }
  //   };
  // }, [botId, uuid, ischat]);

  const isFull = isAppDomain || isMobile;
  const color = chatbot?.chatbotColor ? chatbot?.chatbotColor : "#3047EC";
  return (
    <div
      style={{
        width: !isMobile && "390px",
        height: !isMobile && "570px",
        zIndex: 999999,
        background:
          page == "home"
            ? `linear-gradient(to bottom, ${color} 2%, #FFFFFF 57%)`
            : "",
        backdropFilter: "blur(10px)",
      }}
      className={`relative max-w-5xl mx-auto ${
        isAppDomain ? "w-screen h-full" : isMobile ? "w-full h-full" : " "
      }    overflow-hidden bg-white shadow rounded-2xl text-[14px]`}
    >
      <audio ref={audioRef}>
        <source src="/mp3/notification.mp3" type="audio/mpeg" />
      </audio>
      <div
        className={`w-full h-[10%] flex justify-between items-center px-4 relative`}
        style={{
          backgroundColor: page == "home" ? "" : color,
        }}
      >
        {page != "home" && page != "init" ? (
          <div className="text-[18px] font-bold flex items-center gap-2 w-full relative">
            <div onClick={hanldeClickBack} className="cursor-pointer">
              <ChevronBackIcon className="w-[22px] text-white" />
            </div>
            <p className="text-white">
              {page == "messages" ? "Messages" : dataBot?.name ?? "TaggoAI"}
            </p>
          </div>
        ) : (
          <div className="flex gap-3 items-center justify-between w-full">
            <div className="flex gap-3 items-center">
              {chatbot?.chatbotIcon && !imageError ? (
                <img
                  src={`${PathImage}${chatbot.chatbotIcon}`}
                  className="w-[32px] h-[32px]"
                  onError={() => setImageError(true)}
                  alt="chatbot icon"
                />
              ) : (
                <ChatIcon
                  style={{ color: "#fff" }}
                  className="w-[32px] h-[32px]"
                />
              )}
              <div className="text-[18px] text-white font-bold">
                <p>{dataBot?.name ?? "TaggoAI"}</p>
              </div>
            </div>
            {page == "init" && (
              <ChatbubblesIcon
                style={{ color: "#fff" }}
                className="w-[24px]"
                onClick={() => setCurrentPage("messages")}
              />
            )}
          </div>
        )}
      </div>
      {page != "home" && (
        <div className={`w-full h-[90%] ${page == "messages" && ""}`}>
          <ChatNew
            chatbot={dataBot}
            botId={botId}
            dataBot={dataBot}
            closeMess={closeMess}
            setCloseMess={setCloseMess}
            uuid={uuid}
            lead={lead}
            setLead={setLead}
            // sendAgent={sendAgent}
            messagesold={messages}
            flows={flows}
            prompt={prompt}
            flow={flow}
            checkContinue={checkContinue}
            loading={loading}
            setPrompt={setPrompt}
            setFlow={setFlow}
          />
        </div>
      )}
      {page == "home" && (
        <div className="w-full h-[90%] p-[10px] overflow-y-auto scrollable-style relative pb-[20px]">
          <InitComponent
            chatbot={dataBot}
            flows={flows}
            setNewChat={setNewChat}
            setPrompt={setPrompt}
            setFlow={setFlow}
            HanldeGetDataMess={HanldeGetDataMess}
            setMessages={setMessages}
            messages={messages}
          />
        </div>
      )}
      {(page === "home" || page == "messages") && (
        <div className="absolute bottom-2 left-0 w-full  flex justify-center items-center py-2">
          <button
            onClick={() => {
              setCurrentPage("chat");
              setPrompt(undefined);
              setFlow(undefined);
              // setMessages([]);
              setInbox("");
            }}
            style={{
              backgroundColor: chatbot?.chatbotColor || "#3047EC",
            }}
            className={`px-3 h-10 text-white font-bold  rounded-[22px] flex gap-3 items-center`}
          >
            <span>{translations?.send_us_a_message}</span>
            <SendIcon className="w-[24px] text-white cursor-pointer" />
          </button>
        </div>
      )}
    </div>
  );
}
