export const Button = ({ onClick, label, icon }) => {
  return (
    <button type="submit" onClick={onClick}>
      <div className="w-full p-2 border-gray-300 rounded-lg bg-[#e5eeff] hover:bg-blue-700 hover:text-white cursor-pointer">
        {label}
        {icon && <span>{icon}</span>}
      </div>
    </button>
  );
};
