import { useChat } from "ai/react";
import { useEffect, useMemo, useState, useRef } from "react";
import { insertDataIntoMessages } from "./transform";
import { ChatInput, ChatMessages } from "./ui/chat";
import { useApp } from "../AppContext";
//import io from "socket.io-client";
import ListInboxComponent from "./ui/newchat/ListInbox";
import LoadingMess from "./ui/LoadingMess";
import io from "socket.io-client";

import {
  firstWords,
  accessToken,
  socketURL,
  appChatURL,
  appURL,
  isMobile,
  generateString,
  isValidJson,
  PathImage,
} from "../libs";
import axios from "axios";

let Id;
export default function ChatSection({
  botId,
  dataBot,
  model,
  closeMess,
  setCloseMess,
  uuid,
  setLead,
  lead,
  sendAgent,
  messagesold,
  flows,
  newchat,
  prompt,
  newFlow,
  checkContinue,
  loading,
}) {
  const [file, setFile] = useState();
  const audioRef = useRef(null);
  const [filePath, setFilePath] = useState();

  const [isAgent, setIsAgent] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [checkLead, setcheckLead] = useState(false);
  // const [flow, setFlow] = useState();
  const [messResult, setMessResult] = useState([]);
  const ws = useRef(null);

  const {
    setInbox,
    inboxId,
    page,
    chatbot,
    setCurrentPage,
    flow,
    setFlow,
    setNotifi,
    ischat,
    setIschat,
  } = useApp();
  const [listMessages, setListMessages] = useState();

  useEffect(() => {
    if (inboxId && inboxId !== Id) {
      Id = inboxId;
    }
    if (inboxId) {
      hanldeGetMessById(inboxId);
    }
  }, [inboxId]);
  useEffect(() => {
    Id = undefined;
    setNotifi(false);
    setIschat(true);
  }, []);

  const hanldeGetMessById = async (id) => {
    await axios
      .get(`${appURL}/api/inbox/messages/?inboxId=${id}`)
      .then((res) => {
        const sortdata = res.data.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        const newData = sortdata.map((item) => ({
          id: item._id,
          role: item.type,
          content: item.message,
          file: item?.file,
        }));
        setListMessages(newData);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const hanldeGetmess = (mess) => {
    setIsAgent(false);
    setIsMenu(true);
    if (mess.content) {
      // console.log("inboxId", inboxId);
      handleCreateMess(false, mess.content, mess.role);
    } else {
      append({
        content:
          "Please wait a few minutes to receive a response from our support staff",
        id: generateString(8),
        role: "assistant",
      });
    }
  };

  const {
    messages,
    input,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    setMessages,
    stop,
    data,
    setInput,
    append,
  } = useChat({
    api: `${appChatURL}/api/assistant/${botId}`,
    body: {
      data: {
        // fileUri: filePath ? `${PathImage}${filePath}` : null,
        botId,
        isAgent: dataBot?.isAgent,
        uuid,
        leadId: lead?._id,
        isWebchat: true,
        //model: 'gpt-4'
      },
    },
    onFinish: hanldeGetmess,
    // headers: {
    //   "Content-Type": "application/json", // using JSON because of vercel/ai 2.2.26
    // },
  });
  useEffect(() => {
    if (listMessages && listMessages.length > 0) {
      setMessages(listMessages);
    }
  }, [listMessages]);

  useEffect(() => {
    if (closeMess) {
      setMessages([]);
      setCloseMess(false);
    }
  }, [closeMess]);
  useEffect(() => {
    if (!lead && dataBot?.isLead && messages.length === 4) {
      setcheckLead(true);
    }
  }, [lead, messages]);

  useEffect(() => {
    if (prompt && newchat && messages.length === 0) {
      const Message = [
        { content: prompt, id: generateString(8), role: "user" },
      ];
      setMessages(Message);
      reload();
      handleCreateMess(true, prompt, "user");
    }
  }, []);

  const transformedMessages = useMemo(() => {
    return insertDataIntoMessages(messages, data);
  }, [messages, data]);

  // useEffect(() => {
  //   if (botId && uuid && ischat) {
  //     ws.current = new WebSocket(`${socketURL}/api/inbox`);
  //     ws.current.onopen = () => {
  //       console.log("Connected to WebSocket");
  //       // Send botId and uuid upon connection
  //       const clientInfo = { botId, uuid, role: 'user' };
  //       // console.log("clientInfo", clientInfo);
  //       if (ws.current) {
  //         ws.current.send(JSON.stringify(clientInfo));
  //       }
  //     };

  //     ws.current.onmessage = (event) => {
  //       let receivedMessage;

  //       // Check if the message is a Buffer and convert it to string
  //       if (event.data instanceof ArrayBuffer) {
  //         const buffer = new Uint8Array(event.data);
  //         const decoder = new TextDecoder("utf-8");
  //         receivedMessage = JSON.parse(decoder.decode(buffer));
  //       } else {
  //         // Assume it's a regular text message
  //         receivedMessage = isValidJson(event.data)
  //           ? JSON.parse(event.data)
  //           : null;
  //       }
  //       const formattedMessage = {
  //         message: receivedMessage?._doc?.message,
  //         type: receivedMessage?._doc?.type,
  //         read: receivedMessage?._doc?.read,
  //         inboxId: receivedMessage?._doc?.inboxId,
  //         createdAt: receivedMessage?._doc?.createdAt,
  //         file: receivedMessage?._doc?.file ?? null,
  //       };

  //       if (
  //         receivedMessage?.botId === botId &&
  //         receivedMessage?.uuid === uuid
  //       ) {
  //         if (formattedMessage.type === "agent") {
  //           playAudio();
  //           const messAssistant = {
  //             content: formattedMessage.message,
  //             id: generateString(8),
  //             role: "agent",
  //             data:
  //               formattedMessage.file && formattedMessage.file?.uri
  //                 ? {
  //                     type: formattedMessage.file.type,
  //                     image_url: {
  //                       url: `${PathImage}${formattedMessage.file.uri}`,
  //                     },
  //                   }
  //                 : null,
  //           };
  //           setMessages((prevMessages) => [...prevMessages, messAssistant]);
  //           reload();
  //         }
  //         console.log("Received message:", formattedMessage);
  //       }
  //     };
  //   }
  //   return () => {
  //     if (ws.current) {
  //       ws.current.close();
  //     }
  //   };
  // }, [botId, uuid, ischat]);
  useEffect(() => {
    const socket = io(socketURL);
    socket.on("connect", () => {
      // console.log("Is connected");
    });

    socket.on(`message ${botId} ${uuid}`, (message) => {
      if (message.type === "agent") {
        let Array = messages;
        const messAssistant = {
          content: message.message,
          id: generateString(8),
          role: "agent",
          data: message.file
            ? {
                type: message.file.type,
                image_url: {
                  url: `${appURL}/${message.file.uri}`,
                },
              }
            : null,
        };
        Array.push(messAssistant);
        setMessages(Array);
        reload();
        playAudio();
      }
    });
    socket.on("connect_error", (err) => {
      //console.log(`connect_error due to ${err.message}`);
    });
    return () => {
      socket.disconnect();
    };
  }, [botId, uuid, messages]);
  const handleCreateMess = async (isUser, mess, role) => {
    const fileAttach = {
      type: file ? file.type : "",
      name: file ? file.name : "",
      uri: filePath ? `${filePath}` : "",
    };
    const body = {
      name: firstWords(mess),
      message: mess,
      botId,
      type: role,
      // read: true,
      uuid,
      file: filePath && role === "user" ? fileAttach : null,
      leadId: lead?._id,
      inboxId: Id,
      userId: dataBot?.userId,
    };
    //create new message.
    if (role == "user" || (role == "assistant" && Id)) {
      await axios
        .post(`${appURL}/api/inbox`, body)
        .then((res) => {
          if (!Id) {
            setInbox(res.data?.inboxId);
            Id = res.data?.inboxId;
          }
          setFilePath(undefined);
          setFile(undefined);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
      });
    }
  };

  return (
    <div
      style={{ height: !isMobile && "100%" }}
      className={`max-w-5xl max-md:max-w-[100vw] w-full  ${
        isMobile && "h-full"
      }  overflow-hidden `}
    >
      <audio ref={audioRef}>
        <source src="/mp3/notification.mp3" type="audio/mpeg" />
      </audio>
      {page == "messages" ? (
        loading ? (
          <LoadingMess />
        ) : (
          <div className="w-full h-full flex flex-col">
            {messagesold && messagesold.length > 0 ? (
              <ListInboxComponent inboxs={messagesold} />
            ) : (
              <div class="flex justify-center items-center h-full">
                <p className="">No conversations yet</p>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="w-full h-full flex flex-col">
          <ChatMessages
            dataBot={dataBot}
            messages={transformedMessages}
            isLoading={isLoading}
            setInput={setInput}
            input={input}
            handleSubmit={handleSubmit}
            handleCreateMess={handleCreateMess}
            reload={reload}
            stop={stop}
            append={append}
            setIsAgent={setIsAgent}
            sendAgent={sendAgent}
            lead={lead}
            uuid={uuid}
            botId={botId}
            setMessages={setMessages}
            mess={messages}
            isMenu={isMenu}
            setIsMenu={setIsMenu}
            closeMess={closeMess}
            setCloseMess={setCloseMess}
            checkLead={checkLead}
            setLead={setLead}
            setcheckLead={setcheckLead}
            flows={flows}
            setFlow={setFlow}
            flow={flow}
            messResult={messResult}
            setMessResult={setMessResult}
            isAgent={isAgent}
            newchat={newchat}
            newFlow={newFlow}
          />

          <ChatInput
            input={input}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            textPlaceholder={dataBot?.textPlaceholder}
            isLoading={isLoading}
            multiModal={
              process.env.NEXT_PUBLIC_MODEL === "gpt-4-vision-preview"
            }
            handleCreateMess={handleCreateMess}
            botId={botId}
            setFilePath={setFilePath}
            filePath={filePath}
            setFile={setFile}
            setMessages={setMessages}
            checkLead={checkLead}
            flow={flow}
            setInput={setInput}
            append={append}
            flows={flows}
            messages={messages}
            messResult={messResult}
            setMessResult={setMessResult}
            setFlow={setFlow}
            reload={reload}
            lead={lead}
            isAgent={isAgent}
          />
        </div>
      )}
    </div>
  );
}
