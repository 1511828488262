import { useState, useEffect } from "react";
import { Button } from "../element/button";
import ChatLead from "../chat/chat-lead";
import { useApp } from "../../../AppContext";

export const ListFlow = ({ flow, hanldeClick, dataBot, uuid }) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const { flowWait, changeFlowWait, flowUser, changeFlowUser } = useApp();
  // console.log("nextFlow", displayedItems, nextFlow);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (flowWait) {
      setDisplayedItems([]);
    }
    const displayItemsWithConditionalDelay = async () => {
      if (flow?.options?.length > 0) {
        for (let i = 0; i < flow.options.length; i++) {
          const item = flow.options[i];
          setDisplayedItems((prev) => [...prev, item]);

          if (item.type === "user") {
            const remainingItems = flow.options.slice(i + 1);
            changeFlowWait({ ...flow, options: remainingItems });
            changeFlowUser(item);
            break;
          }
          if (item.type !== "user") {
            changeFlowWait();
            changeFlowUser();
          }

          if (item.type === "image") {
            await delay(1000);
          }
          if (item.type === "lead") {
            await delay(3000);
          }
          if (item.type === "next") {
            hanldeClick(item);
          }
        }
      }
    };

    displayItemsWithConditionalDelay();
  }, [flow]);

  return (
    <div className="w-full flex gap-4 justify-center flex-wrap">
      {displayedItems?.map((item, idx) => {
        return (
          <div
            key={idx}
            className={`flex items-start gap-4 justify-center ${
              (item.type === "image" || item.type === "lead") && "w-full px-2"
            }`}
          >
            {item.type === "button" && (
              <Button
                onClick={() => {
                  setDisplayedItems([]);
                  hanldeClick(item);
                }}
                label={item?.name}
              />
            )}

            {item.type === "image" && (
              <div className="w-full">
                <img
                  src={item.data[0]}
                  alt={item.name}
                  className="max-w-full h-auto"
                />
              </div>
            )}

            {item.type === "button:link" && (
              <a href={item.data[0] ? item.data[0] : ""} target="_blank">
                <Button
                  label={item?.name}
                  //   icon={<openIcon className="w-[18px] text-gray-500" />}
                />
              </a>
            )}

            {item.type === "button:phone" && (
              <a href={item?.data[0] ? `tel:${item?.data[0]}` : ""}>
                <Button label={item?.name} />
              </a>
            )}

            {item.type === "lead" && (
              <div className="w-[80%]">
                <ChatLead
                  uuid={uuid}
                  botId={dataBot._id}
                  nextstep={() => hanldeClick(item)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
